import * as React from "react"
import debounce from "lodash.debounce"
import { Link } from "gatsby"
import { StoreContext } from "../../context/store-context"
import { formatPrice } from "../../utils/format-price"
import { GatsbyImage } from "gatsby-plugin-image"
import { getShopifyImage } from "gatsby-source-shopify"
import { NumericInput } from "./numeric-input"
import { TiDeleteOutline } from "react-icons/ti"

export function LineItem({ item }) {
  const { removeLineItem, checkout, updateLineItem, loading } =
    React.useContext(StoreContext)
  const [quantity, setQuantity] = React.useState(item.quantity)

  const variantImage = {
    ...item.variant.image,
    originalSrc: item.variant.image.src,
  }
  const price = formatPrice(
    item.variant.priceV2.currencyCode,
    Number(item.variant.priceV2.amount)
  )

  const subtotal = formatPrice(
    item.variant.priceV2.currencyCode,
    Number(item.variant.priceV2.amount) * quantity
  )

  const handleRemove = () => {
    removeLineItem(checkout.id, item.id)
  }

  const uli = debounce(
    (value) => updateLineItem(checkout.id, item.id, value),
    300
  )
  // eslint-disable-next-line
  const debouncedUli = React.useCallback((value) => uli(value), [])

  const handleQuantityChange = (value) => {
    if (value !== "" && Number(value) < 1) {
      return
    }
    setQuantity(value)
    if (Number(value) >= 1) {
      debouncedUli(value)
    }
  }

  function doIncrement() {
    handleQuantityChange(Number(quantity || 0) + 1)
  }

  function doDecrement() {
    handleQuantityChange(Number(quantity || 0) - 1)
  }

  const image = React.useMemo(
    () =>
      getShopifyImage({
        image: variantImage,
        layout: "constrained",
        crop: "contain",
        width: 160,
        height: 160,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [variantImage.src]
  )

  return (
    <li className="flex py-6">
      <div className="flex-shrink-0">
        {image && (
          <GatsbyImage
            key={variantImage.src}
            image={image}
            alt={variantImage.altText ?? item.variant.title}
            className="w-24 h-24 rounded-md object-center object-cover sm:w-32 sm:h-32"
          />
        )}
      </div>
      <div className="ml-4 flex-1 flex flex-col sm:ml-6">
        <div>
          <div className="flex justify-between">
            <h4 className="text-base lg:text-lg">
              <Link
                to=""
                className="font-medium text-secondary hover:text-tertiary"
              >
                {item.title}
                {item.storefrontId}
              </Link>
            </h4>
            <p className="ml-4 text-base font-medium text-secondary">{price}</p>
          </div>
          <p className="mt-1 text-base text-gray-600">
            {item.variant.title === "Default Title" ? "" : item.variant.title}
          </p>
        </div>
        <div className="mt-4 flex-1 flex items-end justify-between">
          <p className="flex items-center text-base text-secondary space-x-2">
            {/* <CheckIcon
              className="flex-shrink-0 h-5 w-5 text-green-500"
              aria-hidden="true"
            />
            <span>{"In stock"}</span> */}
          </p>
          <div className="ml-4 text-right">
            <div>
              <NumericInput
                disabled={loading}
                value={quantity}
                aria-label="Quantity"
                onIncrement={doIncrement}
                onDecrement={doDecrement}
                onChange={(e) => handleQuantityChange(e.currentTarget.value)}
              />
            </div>
            <button
              onClick={handleRemove}
              type="button"
              className="mt-2 text-base text-tertiary font-medium hover:text-secondary"
            >
              <span className="">
                <TiDeleteOutline size={21} />
              </span>
            </button>
          </div>
        </div>
      </div>
    </li>
  )
}
