import * as React from "react"
import { graphql, Link } from "gatsby"
import { Seo } from "../components/seo"
import { Layout } from "../layouts/layout"
import { StoreContext } from "../context/store-context"
import { LineItem } from "../components/products/line-item"
import { formatPrice } from "../utils/format-price"

const CartPage = ({ data }) => {
  const { checkout, loading } = React.useContext(StoreContext)
  const emptyCart = checkout.lineItems.length === 0

  const handleCheckout = () => {
    window.open(checkout.webUrl)
  }

  let isEnglish = data.context.context.nodeLocale === "en-CA"
  const path = data.context.context.pathNoLanguagePrefix

  return (
    <Layout isEnglish={isEnglish} path={path}>
      <div className="max-w-3xl mx-auto lg:py-12 px-4 py-6 sm:px-6 lg:px-0">
        {emptyCart ? (
          <div className="min-h-[60vh] flex text-center gap-2">
            <div className="m-auto">
              <h1 className="">
                {isEnglish ? "Your cart is empty" : "Votre panier est vide"}
              </h1>
              <Link
                to={isEnglish ? "/en/shop" : "/shop"}
                className="text-tertiary font-medium hover:text-secondary"
              >
                {isEnglish ? "Explore products" : "Explorer les produits"}
                <span aria-hidden="true"> &rarr;</span>
              </Link>
            </div>
          </div>
        ) : (
          <>
            {/* <h1 className="text-3xl font-extrabold text-center tracking-tight text-secondary sm:text-4xl">
              {data.page.title}
            </h1> */}
            <div className="mt-6 min-h-[55vh]">
              <section aria-labelledby="cart-heading">
                <h2 id="cart-heading" className="sr-only">
                  Items in your shopping cart
                </h2>
                <ul className="border-t border-b border-gray-200 divide-y divide-gray-200">
                  {checkout.lineItems.map((item) => (
                    <LineItem item={item} key={item.id} />
                  ))}
                  {/* Order summary */}
                  <section
                    aria-labelledby="summary-heading"
                    className="mt-10 py-6"
                  >
                    <h2 id="summary-heading" className="sr-only">
                      Order summary
                    </h2>

                    <div>
                      <dl className="space-y-4">
                        <div className="flex items-center justify-between">
                          <dt className="text-lg font-medium text-secondary">
                            {isEnglish ? "Subtotal" : "Sous-total"}
                          </dt>
                          <dd className="ml-4 text-lg font-medium text-secondary">
                            {formatPrice(
                              checkout.totalPriceV2.currencyCode,
                              checkout.totalPriceV2.amount
                            )}
                          </dd>
                        </div>
                      </dl>
                      <p className="mt-1 text-base text-gray-500">
                        {isEnglish
                          ? "Shipping and taxes will be calculated at checkout"
                          : "Les frais d'expédition et les taxes seront calculés lors du passage à la caisse."}
                      </p>
                    </div>

                    <div className="mt-10">
                      <button
                        type="submit"
                        className="w-full flex-1 bg-secondary border border-transparent rounded-md py-3 px-5 flex items-center justify-center text-base font-medium text-white hover:bg-tertiary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-secondary sm:w-full"
                        onClick={handleCheckout}
                        disabled={loading}
                      >
                        {isEnglish ? "Checkout" : "Caisse"}
                      </button>
                    </div>

                    <div className="mt-6 text-base text-center">
                      <p>
                        {isEnglish ? "or " : "ou "}
                        <Link
                          to={isEnglish ? "/en/shop" : "/shop"}
                          className="text-tertiary font-medium hover:text-secondary"
                        >
                          {isEnglish
                            ? "Continue shopping"
                            : "Continuer les achats"}
                          <span aria-hidden="true"> &rarr;</span>
                        </Link>
                      </p>
                    </div>
                  </section>
                </ul>
              </section>
            </div>
          </>
        )}
      </div>
    </Layout>
  )
}

export default CartPage

export const Head = ({ data }) => <Seo title={data.page.title} />

export const query = graphql`
  query CartPage($slug: String!, $nodeLocale: String!) {
    page: contentfulCartPage(
      slug: { eq: $slug }
      node_locale: { eq: $nodeLocale }
    ) {
      id
      node_locale
      title
      slug
    }
    context: sitePage(
      context: { nodeLocale: { eq: $nodeLocale }, slug: { eq: $slug } }
    ) {
      id
      path
      context {
        slug
        nodeLocale
        pathNoLanguagePrefix
      }
    }
  }
`
